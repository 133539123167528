import { DatePipe } from './../pipe/date.pipe';
import { FormsModule } from '@angular/forms';
import { MascaraCPFPipe } from './../pipe/mascara-cpf.pipe';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    SelectDropDownModule
  ],
  declarations: [HeaderComponent, FooterComponent, MascaraCPFPipe, DatePipe],
  exports: [HeaderComponent, FooterComponent]
})
export class PartialModule { }
