import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateCustomFormat'
})
export class DatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    
    if(value.length == 2) { value = value + "/" }
    if(value.length == 5) { value = value + "/" }
    
    return value;
  }

}
