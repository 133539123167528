import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  title = 'Nasbit';

  constructor(translate: TranslateService) {

    translate.addLangs(['ptbr','enus']);
    translate.setDefaultLang('ptbr');
    translate.use('ptbr');
  }

}
