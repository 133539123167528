import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NashbitApiService {

  private config = {
    coins: {
      url : 'https://exchange.nashbit.trade/nashbitAPIs/services/api/cotacao/site' 
    }
  }

  constructor(private http: HttpClient) { }

  getCoinsValues () {
    return this.http.get(this.config.coins.url);
  }
  
}
