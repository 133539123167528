import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  
  map: object;
  retorno: object;
  
  constructor(private http: HttpClient) {
    
    this.map = {
      countryByName: "https://restcountries.eu/rest/v2/name/"
    }
    
    this.retorno = {
      step: 1,
      msg: {
        status: 0,
        text: ''
      }
    }
    
  }
  
  getCountryCode (name: string ) {
    let url = this.map['countryByName']+name;
    return this.http.get(url);
  }

  checkSignUpFormStep1 (form: object, step: number, personType: string) : object {
    
    this.retorno['step'] = 1;

    if (form['pais'][0] == "Brasil") {
      
      if (form['document'] != '' && form['document'] != undefined && form['birthday'] != '' && form['birthday'] != undefined) {
        this.retorno['msg'].status = true;
      }
      
      if (this.retorno['msg'].status) {
        
        if (personType == 'juridica') {
          console.log(form['document']);
          if (form['document'].length < 18) {
            this.retorno['msg'].status = false;
            this.retorno['msg'].text = "CNPJ Incorreto.";
          } 
        } else {
          console.log(form['document']);
          if (form['document'].length < 11) {
            this.retorno['msg'].status = false;
            this.retorno['msg'].text = "CPF Incorreto.";
          } 
        }
      }
      
    } else {
      
      if (form['name'] != '' && form['name'] != undefined && form['birthday'] != '' && form['birthday'] != undefined) {
        this.retorno['msg'].status = true;
      }
      
      if (this.retorno['msg'].status) {
        let size = form['name'].split(' ');
        if (size <= 1) {
          this.retorno['msg'].status = false;
          this.retorno['msg'].text = "Nome Incorreto, por favor preencha o campo corretamente.";
        }
      }
      
    }
    
    if (form['birthday'] != '') {
      let data = form['birthday'];
      let parts = data.split("/");
      let date = new Date();
      
      if (parts[0] > 31) {
        this.retorno['msg'].status = false;
        this.retorno['msg'].text = "Data Incorreta";
      }
      
      if (parts[1] > 12) {
        this.retorno['msg'].status = false;
        this.retorno['msg'].text = "Data Incorreta";
      }
      
      if (parts[2] > date.getFullYear() || parts[2] < 1920 ) {
        this.retorno['msg'].status = false;
        this.retorno['msg'].text = "Data Incorreta";
      }
      
    }
    
    if (this.retorno['msg'].status) {
      this.retorno['step']++;
    }
    
    return this.retorno;
    
  }
  
  checkSignUpFormStep2 (form: object, step: number, pass1: string, pass2: string) : object {
    
    this.retorno['msg'].status = true;
    let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
    if (!re.test(form['email'])) {
      this.retorno['msg'].status = false;
      this.retorno['msg'].text = "E-mail inválido";
    }
    
    if (this.retorno['msg'].status) {
      
      if (pass1 != undefined) {
        
        if (pass1 != pass2) {
          this.retorno['msg'].status = false;
          this.retorno['msg'].text = "As senhas não são iguais!"
        } 
        
      } else {
        this.retorno['msg'].status = false;
        this.retorno['msg'].text = "Por favor Preencher a senha!"
      }
    }
    
    return this.retorno;
    
  }
  
  verifyCountry (obj: object) {

    if ( Array.isArray(obj['pais'])) {
      obj['pais'] = obj['pais'][0];
    }

    this.getCountryCode(obj['pais'])
      .subscribe( data => {
        obj['pais'] = data[0].alpha2Code;
      });

    return obj;

  }

}
