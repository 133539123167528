import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  target: object = {
    signup: 'https://exchange.nashbit.trade/nashbitAPIs/services/api/web/addUser',
    login: 'https://exchange.nashbit.trade/nashbitAPIs/services/api/web/login',
    //authenticator: 'https://exchange.nashbit.trade/nashbitAPIs/services/api/autenticator',
    authenticator: 'https://nashbit.trade/autenticador',
    getIp : 'https://jsonip.com',
    resend: 'https://exchange.nashbit.trade/nashbitAPIs/services/api/email/reenvia'
  }

  temporaryToken: string = '';
  email: string = '';

  constructor(private http: HttpClient) { }

  signup (obj) {
    
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');

    let payload = {
      nashbit : {
        user: obj
      }
    }
    
    return this.http.post(this.target['signup'], payload, {headers: headers});

  }

  login (obj: object) {
    
    let url = this.target['login'];
    
    let payload = {
      nashbit: {
        user: {
          user: obj['email'],
          password: obj['password']
        }
      }
    }

    return this.http.post(url, payload, { headers: { 'Content-Type':'application/json' }, observe: 'response'});
  }

  setTemporaryToken (token: string) {
    this.temporaryToken = token;
  }

  setEmail (str: string) {
    this.email = str;
  }

  sendAuthenticator (str: string) {
    window.location.href = this.target['authenticator']+"/"+str;
  }

  sendConfirmationEmail() {
    let payload = {
      nashbit: {
        user: {
          token: this.temporaryToken
        }
      }
    }

    return this.http.post(this.target['resend'],payload);
  }

  getUserIp () {
    return this.http.get(this.target['getIp']);
  }

  getEmail () {
    return this.email;
  }

  saveLocalStorage ( str: string ) {
    
    window.localStorage.setItem('nashbit-authenticator', str);
    window.localStorage.setItem('nashbit-authenticator-xcsrf', sessionStorage.getItem('x-csrf'));
    
    let cookieStr = "nashbit|"+str+"|"+sessionStorage.getItem('x-csrf');
    document.cookie = cookieStr;
    
    //let db = openDatabase("winkles", "1.0", "Winkles Of The World", 32678);
    return true;
  }

}
