import { ErrorComponent } from './error/error.component';
import { PoliticaComponent } from './politica/politica.component';
/* Angular 2 Routing */
import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/* StyleGuide Position */
import { HomeComponent } from './home/home.component';

const APP_ROUTES: Routes = [
  { path: '', component: HomeComponent },
  { path: 'politica', component: PoliticaComponent },
  { path: 'termo-mobile.html', component: PoliticaComponent },
  { path: 'privacidade-mobile.html', component: PoliticaComponent },
  { path: 'privacidade', component: PoliticaComponent },
  { path: 'termo', component: PoliticaComponent },
  { path: '**', component: ErrorComponent }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(APP_ROUTES);
