import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mascaraCPF'
})
export class MascaraCPFPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    
    let length = value.length;
  
    if (value != undefined) {
      
      if (value.match(/[a-zA-Z]/gi)) {

        value = value.replace(/[a-zA-Z]/gi, "");
        return value;

      } else {

        if (args == 'fisica') {
          
          value=value.replace(/\D/g,"");
          value=value.replace(/^(\d{3})(\d)/,"$1.$2");
          value=value.replace(/^(\d{3})\.(\d{3})(\d)/,"$1.$2.$3");
          value=value.replace(/\.(\d{3})(\d)/,".$1-$2");

        } else {

          value=value.replace(/\D/g,"");
          value=value.replace(/^(\d{2})(\d)/,"$1.$2");
          value=value.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3");
          value=value.replace(/\.(\d{3})(\d)/,".$1/$2");
          value=value.replace(/(\d{4})(\d)/,"$1-$2");
        }
        
        return value;

      }

    }
  }

}
