import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';

@Component({
  selector: 'app-politica',
  templateUrl: './politica.component.html',
  styleUrls: ['./politica.component.scss']
})
export class PoliticaComponent implements OnInit {

  elementStatus: boolean;

  constructor(private deviceService: DeviceDetectorService, private router: Router) {
  }

  ngOnInit() {

    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();

    if (this.router.url.match(/termo/g) ) {
      this.elementStatus = true;
    } else {
      this.elementStatus = false;
    }

  }

}
