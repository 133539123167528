import { UserService } from './../../service/user.service';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ElementRef, ViewChild, HostListener, TemplateRef } from '@angular/core';

import { NashbitApiService } from './../../service/nashbit-api.service';
import { TranslateService } from '@ngx-translate/core';
import { StompService } from '@stomp/ng2-stompjs';
import { BsModalService } from 'ngx-bootstrap/modal';

import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Message } from '@stomp/stompjs';

import 'rxjs/add/operator/map'
import { FormService } from '../../service/form.service';

@Component({
  selector: 'footcoin-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @ViewChild("menu") 
    menu: ElementRef;

  @ViewChild("headerContent") 
    headerContent: ElementRef;

  @ViewChild("text") 
    text: ElementRef;

  @ViewChild("mobileBurguer") 
    mobileBurguer: ElementRef;

  @ViewChild("language") 
    language: ElementRef;

  @ViewChild("store") 
    store: ElementRef;

  @ViewChild("prices") 
    prices: ElementRef;

  @ViewChild("mobilePrices") 
    mobilePrices: ElementRef;

  @HostListener('window:scroll', ['$event']) onScrollEvent($event){
    let scrollPosition = $event.target.scrollingElement.scrollTop;
    this.scrollContentTop(scrollPosition);
  }

  topic: string = '/topic/pricevolume';
  
  intervalFunction;
  modalRef: BsModalRef;

  selectOptions: Array<any>;
  
  selectConfig: object;
  cadastro: object;
  userLogin : object;
  
  msg: string;
  personType: string = "fisica";
  errorClass: string;
  steps: number = 3;
  
  passwordTyping01: string;
  passwordTyping02: string;
  
  strength: boolean = true;
  checkBox: boolean = false;
  resend: boolean = false;
  showLoading: boolean = false;
  timer: any;
  
  modalType: string = 'login';

  private header = {
    enus: "The Premier Cryptocurrency Plataform in South America.",
    ptbr: "A melhor plataforma de criptomoedas do mercado."
  }
  public coins = {
    nashbit: {
      bitcoin: {
        img: "assets/images/moedas/bitcoin.svg",
        alt: "Cotação BitCoin",
        last: "0",
        status: "up",
        percentage: "reloading"
      },
      bitcoincash: {
        img: "assets/images/moedas/bitcoincash.svg",
        alt: "Cotação BitCoinCash",
        last: "0",
        status: "up",
        percentage: "reloading"
      },
      litecoin: {
        img: "assets/images/moedas/litecoin.svg",
        alt: "Cotação Litecoin",
        last: "0",
        status: "up",
        percentage: "reloading"
      },
      dash: {
        img: "assets/images/moedas/dashcoin.svg",
        alt: "Cotação DashCoin",
        last: "0",
        status: "up",
        percentage: "reloading"
      },
      ethereum: {
        img: "assets/images/moedas/ethereum.svg",
        alt: "Cotação Ethereum",
        last: "0",
        status: "up",
        percentage: "reloading"
      },
    }
  }

  constructor ( 
    public translate: TranslateService, 
    private _stompService: StompService, 
    private NashBitApi: NashbitApiService,
    private modalService: BsModalService,
    private http: HttpClient,
    private user: UserService,
    private form: FormService
  ){

    this.selectConfig = {
      displayKey:"description", 
      search: true,
      placeholder: 'Selecione o País de origem',
      selectedItems: 'Brasil'
    }

    this.cadastro = {
      name: '',
      pais: 'Brasil',
      document: '',
      birthday: '',
      password: '',
      termos: ''
    }

    this.userLogin = {
      email: '',
      password: '',
      userIp: ''
    }
  }

  ngOnInit () {
    this.typeWritter();
    this.getCoinsValues();
    this.setSelectOptions();
    this.getIP();
  }
  
  /* animations */
  typeWritter () {
    let typeWritterInterval;
    let counter = 0;
    let variableTiming = 50;
  
    let titulo = this.text.nativeElement.innerHTML;
    this.text.nativeElement.innerHTML = '';
    
    let typeWritter = (char: string) => {
      variableTiming = Math.round(Math.random() * 700)+ 10;
      this.text.nativeElement.innerHTML = this.text.nativeElement.innerHTML+char;
      counter++;
      if (titulo[counter] == null) {
        clearInterval(this.intervalFunction);
      }
    }
    
    this.intervalFunction = setInterval( () => { typeWritter(titulo[counter]) }, variableTiming);
  }

  menuToggle () {
    this.menu.nativeElement.classList.toggle('active');
    this.mobileBurguer.nativeElement.classList.toggle('active');
    this.language.nativeElement.classList.toggle('active');
  }

  pricesToggle () {
    this.prices.nativeElement.classList.toggle('active');
    this.mobilePrices.nativeElement.classList.toggle('active');
  }

  scrollContentTop (top: number) {
    let percentage = (top / window.outerHeight);
    this.headerContent.nativeElement.style.opacity = 1 - (percentage*1.8);
    if (this.store) {
      this.store.nativeElement.style.opacity = 1 - (percentage*1.8);
    }
  }
  
  /* languages */
  selectLanguage (language: string) {
    this.translate.use(language);
    this.text.nativeElement.innerHTML = this.header[language];
    clearInterval(this.intervalFunction);

    if (this.menu.nativeElement.classList.contains('active')) {
      this.menuToggle();
    }
  }
  
  /* Funcionality */
  getCoinsValues () {
    
    this.NashBitApi.getCoinsValues()
      .subscribe( (data: object) => {
        
        for (let i=0; i < data['nashbit'].length; i++) {
          
          let coinName = data['nashbit'][i].moedas.label.toLowerCase();

          this.coins['nashbit'][coinName].last     = data['nashbit'][i].ultimoValorNegociado;
          this.coins['nashbit'][coinName].variacao = data['nashbit'][i].variacao.toFixed(2);
          this.coins['nashbit'][coinName].status   = data['nashbit'][i].varicoStatus;

        }

      });

      this.listenToCoinsChange();

  }

  listenToCoinsChange () {
      
    let stomp_subscription = this._stompService.subscribe(this.topic);

    stomp_subscription.map((message: Message) => {
      return message.body;
    }).subscribe((msg_body: string) => {
      
      let result = JSON.parse(msg_body);

      for (let i=0; i < result['nashbit'].length; i++) {
          
        let coinName = result['nashbit'][i].moedas.label.toLowerCase();

        this.coins['nashbit'][coinName].last     = Math.round(result['nashbit'][i].lastPrice * 100 ) / 100;
        this.coins['nashbit'][coinName].variacao = result['nashbit'][i].change24h.toFixed(2);
        this.coins['nashbit'][coinName].status   = result['nashbit'][i].changeStatus;

      }

    });

  }

  getCoinClass (status: string) {
    
    let styleClass;
    if (status === "up") {
      styleClass = "positive";
    } else if ( status == 'down') {
      styleClass = "negative";
    }

    return styleClass;
  }
  
  /*
   *  Modal
   */

  openModal(template: TemplateRef<any>, modalType) {
    
    this.cadastro['pais'] = 'Brasil';
    this.steps = 1;
    let ngbModalOptions = {
      keyboard : true,
      ignoreBackdropClick: true,
      animated: true
    };
    this.modalRef = this.modalService.show(template, ngbModalOptions);
    this.changeModal(modalType);
    
  }

  closeModal(temp) {
    this.modalRef.hide();
  }

  changeModal (modalType: string) {
    this.modalType = modalType;
    this.steps = 1;
  }

  setSelectOptions () {

    this.http.get('https://restcountries.eu/rest/v2/all')
      .subscribe( (data: Array<any>)  => {
        let arr = [];

        for (let i=0; i < data.length; i++) {
          if (data[i].name == "Brazil") {
            arr.push("Brasil");
          } else {
            arr.push(data[i].name);
          }
          
        }

        this.selectOptions = arr;        
      });
  }

  changePersonType (str: string) {
    this.personType = str;
  }

  addValueToCadastro ($event, field) {

    if ($event.target != undefined) {
      this.cadastro[field] = $event.target.value;
    } else {
      this.cadastro[field] = $event;
    }
    
  }

  verifyPasswordStrength (str: string) {

    let value = str['target']['value'];
    let els = document.querySelectorAll('.strFactors');
    let params = [];
    let regex = [/\d/gi, /[A-Z]/gi, /[!@#$%&*()]/gi];
    
    for (let i=1; i < 4; i++) {

      if (value.match(regex[i-1])) {
        els[i].classList.add('pass');
        els[i].classList.remove('not-pass');
        params[i] = true;
      } else {
        els[i].classList.add('not-pass');
        els[i].classList.remove('pass');
        params[i] = false;
      }
    }

    if (value.length >= 8) {
      els[0].classList.add('pass');
      els[0].classList.remove('not-pass');
      params[0] = true;
    } else {
      els[0].classList.remove('pass');
      els[0].classList.add('not-pass');
      params[0] = false;
    }

    if (params.indexOf(false) > 0) {
      this.strength = true;
    }

  }

  checkForm () {
    
    let retorno = {};
    console.log(this.steps);
    
    if (this.steps == 1) {
      retorno = this.form.checkSignUpFormStep1(this.cadastro, this.steps, this.personType);
    }

    if (this.steps == 2) {
      retorno = this.form.checkSignUpFormStep2(this.cadastro, this.steps, this.passwordTyping01, this.passwordTyping02);

      if (this.strength) {

        this.cadastro['password'] = this.passwordTyping01;
        this.cadastro = this.form.verifyCountry(this.cadastro);

        if (this.cadastro['termos'] == 'on') {
          this.sendSignUpForm(this.cadastro);
        } else {
          this.showErrorMessage('Por Favor aceite os termos!')
        }

      }
    }

    this.steps = retorno['step'];

    if (!retorno['msg'].status) {
      this.showErrorMessage(retorno['msg'].text);
    }

  }

  submit ($event) {

    $event.preventDefault();
    this.checkForm();
  }

  showErrorMessage (str: string, style? : string) {

    this.msg = str;
    this.errorClass = style;
    let _self = this;

    this.timer = window.setTimeout ( function () {
      _self.clearMessage();
    }, 6000);

  }

  clearMessage () {
    this.msg = '';
    clearTimeout(this.timer);
  }

  loginForm (step) {

    this.showLoading = true;

    this.user.login(this.userLogin)
      .subscribe(data => {
        
        this.showLoading = false;

        if (data.body['nashbit']['blocked']) {
          
          let date = new Date(data.body['nashbit']['timeBlocked']);
          let current = new Date();
          
          let blocked = date.getMinutes() - current.getMinutes();

          this.showErrorMessage('Usuário bloqueado por execesso de tentativas incorretas: Tempo de espera: '+blocked+' minutos');

        } else {

          if (data.body['nashbit']['status'] == undefined) {
            this.showErrorMessage('Usuário e/ou Senha Inválidos');
          }
          
          if (data.body['nashbit']['status'] == 'A') {
            this.user.sendAuthenticator(data.headers.get('oauth'));
          }
          
          if (data.body['nashbit']['status'] == 'V') {
            this.user.setTemporaryToken(data.body['nashbit']['tokenReenviaEmail']);
            this.user.setEmail(data.body['nashbit']['email']);
            this.resend = true;
            this.showErrorMessage("Por Favor, confirme seu e-mail, se não recebeu email, <a (click)='resendConfirmationEmail()' href='#'> clique aqui</a> para receber novamente", "success");
          }

        }

      });

  }

  resendConfirmationEmail () {
    
    this.clearMessage();
    this.resend = false;

    let email = this.user.getEmail();

    this.user.sendConfirmationEmail()
      .subscribe( data => {
        this.showErrorMessage('Foi Reenviado um email para : '+email, 'success');
      });
  }

  jumpToNext (item) {
    let target = item.target;
    target.nextElementSibling.focus();
  }

  sendSignUpForm (obj: object) {

    this.user.signup(obj)
      .subscribe( res => {

        if (res['nashbit']['resposta']) {  
          this.steps++;
        } else {
          this.showErrorMessage(res['nashbit']['message']);
        }

      });
  }

  getIP () {
    this.user.getUserIp()
      .subscribe( data => {
        this.userLogin['userIp'] = data['ip'];
      });
  }


}