import { Component, TemplateRef, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  @ViewChild('main') main: ElementRef;
  @ViewChild('path') path: ElementRef;
  @ViewChild('logoShow') logoShow: ElementRef;
  @ViewChild('advantages') advantages: ElementRef;
  @ViewChild('tax') tax: ElementRef;
  @ViewChild('limit') limit: ElementRef;
  @ViewChild('taxLink') taxLink: ElementRef;
  @ViewChild('limitLink') limitLink: ElementRef;

  @HostListener('window:scroll', ['$event']) onScrollEvent($event){
    
    let scrollPosition = window.scrollY;

    this.scrollEvents (scrollPosition);

  } 
  
  modalRef: BsModalRef;
  platform: boolean = false;
  taxes: boolean = false;

  constructor(public el: ElementRef, private modalService: BsModalService) { }

  ngOnInit() {

  }

  checkShowLogo (total: number) {

    if (total > 1) {
      if (!this.logoShow.nativeElement.classList.contains('active')){
        this.logoShow.nativeElement.classList.add('active');
      }
    } else if (total < 1) {
      if (this.logoShow.nativeElement.classList.contains('active')){
        this.logoShow.nativeElement.classList.remove('active');
      }
    }

  }

  changeTaxesBox (type: string) {
    
    if (type == "tax") {  
      if (!this.tax.nativeElement.classList.contains('active')) {
        this.limit.nativeElement.classList.remove('active');
        this.limitLink.nativeElement.classList.remove('active');
        this.tax.nativeElement.classList.add('active');
        this.taxLink.nativeElement.classList.add('active');
      }
    } else {
      if (!this.limit.nativeElement.classList.contains('active')) {
        this.limit.nativeElement.classList.add('active');
        this.limitLink.nativeElement.classList.add('active');
        this.tax.nativeElement.classList.remove('active');
        this.taxLink.nativeElement.classList.remove('active');
      }
    }
  }

  scrollEvents (scrollPosition: number) {

    const config = {
      about: {
        element: document.querySelector("#about"),
        path: document.querySelectorAll(".aboutPath")[0] as HTMLElement,
        limit: this.el.nativeElement.children[0].offsetTop * 2.5
      },
      advantages : {
        element: document.querySelectorAll(".advantages")[0] as HTMLElement,
        path: document.querySelectorAll(".advantagesPath")[0] as HTMLElement,
        imgs: {
          innovation: document.querySelectorAll('.innovation')[0],
          deposit: document.querySelectorAll('.deposit')[0],
          safety: document.querySelectorAll('.safety')[0],
          experience: document.querySelectorAll('.experience')[0],
          pathend: document.querySelectorAll('.pathend img')[0]
        }
      },
      parent: this.el.nativeElement.children[0].offsetTop
    }
    
    let aboutStart = config.about.element.clientHeight * .8;
    let advantagesStart = scrollPosition - (config.advantages.element.offsetTop * 1.3);
    let altura =  config.parent + scrollPosition;
    
    if ( altura >= aboutStart && altura < config.about.limit ) {

      let scrollPercentage = Math.round( ((scrollPosition-aboutStart) / (config.about.element.clientHeight + aboutStart)) * 100);
      config.about.path.style.height = scrollPercentage + '%';

    }

    if (advantagesStart >= 0 && (advantagesStart/config.advantages.element.clientHeight) < .7 ) {
      let scrollPercentage = Math.round((advantagesStart / (config.advantages.element.clientHeight * .8))* 100);
      config.advantages.path.style.height = scrollPercentage + '%';

      if (scrollPercentage > 20) {
        if (!config.advantages.imgs.innovation.classList.contains('active')) {
          config.advantages.imgs.innovation.classList.toggle('active');
        }
      } else {
        if (config.advantages.imgs.innovation.classList.contains('active')) {
          config.advantages.imgs.innovation.classList.toggle('active');
        }
      }

      if (scrollPercentage > 30) {
        if (!config.advantages.imgs.deposit.classList.contains('active')) {
          config.advantages.imgs.deposit.classList.toggle('active');
        }
      } else {
        if (config.advantages.imgs.deposit.classList.contains('active')) {
          config.advantages.imgs.deposit.classList.toggle('active');
        }
      }

      if (scrollPercentage > 45) {
        if (!config.advantages.imgs.experience.classList.contains('active')) {
          config.advantages.imgs.experience.classList.toggle('active');
        }
      } else {
        if (config.advantages.imgs.experience.classList.contains('active')) {
          config.advantages.imgs.experience.classList.toggle('active');
        }
      }

      if (scrollPercentage > 65) {
        if (!config.advantages.imgs.safety.classList.contains('active')) {
          config.advantages.imgs.safety.classList.toggle('active');
        }
      } else {
        if (config.advantages.imgs.safety.classList.contains('active')) {
          config.advantages.imgs.safety.classList.toggle('active');
        }
      }

    }

    if ((advantagesStart/config.advantages.element.clientHeight) > .8) {

      if (!config.advantages.imgs.pathend.classList.contains('active')) {
        config.advantages.imgs.pathend.classList.toggle('active');
      }

    } else {
      if (config.advantages.imgs.pathend.classList.contains('active')) {
        config.advantages.imgs.pathend.classList.toggle('active');
      }

    }
    
    //console.log(scrollPosition);

    if (scrollPosition > 2200) this.taxes = true;
    if (scrollPosition >= 2800) this.platform = true;

  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

}
